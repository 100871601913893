export const exceptionsFields = [
  'created_at',
  'updated_at',
  'created_user',
  'updated_user',
  'last_osrm_data',
  'Текущее положение ТС',
  'Последнее сообщение',
  'Последние координаты',
  'Статус ретрансляции',
  'Обновление статуса ПОТ',
  'Прогноз прибытия'
]
